$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 2560px,
  xxxl: 3840px,
);

$container-max-widths: (
  xs: 100vw,
  sm: 100vw,
  md: 760px,
  lg: 990px,
  xl: 1012px
);

@import "node_modules/bootstrap/scss/mixins/breakpoints";

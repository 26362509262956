// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rakosmente-frontend-primary: mat-palette($mat-green, 100, 600, 800);
$rakosmente-frontend-accent: mat-palette($mat-teal, A200, A100, A400);

// The warn palette is optional (defaults to red).
$rakosmente-frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rakosmente-frontend-theme: mat-light-theme(
    (
      color: (
        primary: $rakosmente-frontend-primary,
        accent: $rakosmente-frontend-accent,
        warn: $rakosmente-frontend-warn,
      ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($rakosmente-frontend-theme);

/* You can add global styles to this file, and also import other style files */
@import "roboto.scss";
@import "variables";
@import "mixins";
@import "node_modules/bootstrap/scss/bootstrap.scss";
//@import "node_modules/bootstrap/scss/mixins/breakpoints";

// DEBUG helper
// * {
//   box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 255, 0.1);
// }
// *:hover {
//   box-shadow: inset 0px 0px 1px 1px rgba(255, 0, 0, 0.4);
// }

html {
  font-size: 62.5% !important;

  // & * {
  //   outline: none;
  // }
}

html,
body {
  height: 100%;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 300;
  background-color: var(--color-body);
}

h1,
h2 {
  color: var(--color-font-primary);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;

  &:hover {
    color: currentColor;
  }
}

img {
  max-width: 100%;
}

.container {
  @include media-breakpoint-down(sm) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

// form elements
label {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--color-font-primary);

  & .important {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--color-error);
  }
}

.input {
  height: 4.8rem;
  padding: 1.6rem 1rem;
  margin-bottom: 1.9rem;
  background-color: var(--input-background);
  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--color-font-primary);

  &--borderless {
    border: none;
  }
}

.switch {
  &-control {
    padding-left: 4.5rem;
    margin-bottom: 1.3rem;

    & .switch-label {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: var(--color-font-primary-light);

      &:before {
        top: 0;
        left: -4.5rem;
        width: 4rem;
        height: 1.6rem;
        border: none;
        border-radius: 10px;
      }

      &:after {
        top: 0;
        left: -4.5rem;
        width: 1.6rem;
        height: 1.6rem;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
      }

      & a {
        font-weight: 500;
        color: var(--color-font-primary);

        &:hover {
          text-decoration: none;
        }
      }
    }

    & .switch-input:checked ~ .switch-label {
      &::before {
        border-color: var(--color-button);
        background-color: var(--color-button);
      }

      &::after {
        transform: translateX(2.5rem);
      }
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  background-color: var(--color-button);
  color: var(--color-button-alternative);
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: var(--color-button-hover);
    color: var(--color-button-alternative);
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  background-color: var(--color-title-bg);
  color: var(--color-title-font);
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.mayor__text > *:last-child {
  margin-bottom: 0;
}

.static-banner {
  &__text {
    & > * {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: var(--color-font-primary);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// accessibility

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// carousel missing styles -- override component stuff
.carousel {
  &-control {
    &-next {
      justify-content: flex-end;
      padding-right: 0.8rem;
      z-index: 99;
      opacity: 1;

      &-icon {
        display: inline-block;
        width: 1.3rem;
        height: 2.1rem;
        background: url("/assets/icons/carousel/chevron-right.svg") 50%/100% 100%;
        @include media-breakpoint-up(md) {
          width: 3.1rem;
          height: 5.3rem;
        }
        @include media-breakpoint-up(lg) {
          width: 2.8rem;
          height: 4.7rem;
        }
      }
    }

    &-prev {
      justify-content: flex-start;
      padding-left: 0.8rem;
      z-index: 99;
      opacity: 1;

      &-icon {
        display: inline-block;
        width: 1.3rem;
        height: 2.1rem;
        background: url("/assets/icons/carousel/chevron-left.svg") 50%/100% 100%;
        @include media-breakpoint-up(md) {
          width: 3.1rem;
          height: 5.3rem;
        }
        @include media-breakpoint-up(lg) {
          width: 2.8rem;
          height: 4.7rem;
        }
      }
    }
  }

  &-indicators {
    margin-bottom: 0;

    & li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: 0.5;
      transition: opacity 0.6s ease;
    }
  }

  &-caption {
    top: 50%;
    left: 0;
    bottom: unset;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 3.2rem;
    background-color: var(--color-button);
    color: var(--color-button-alternative);
    font-size: 1.6rem;
    line-height: 1.9rem;
    transform: translateY(-50%);
    opacity: 0.9;
    @include media-breakpoint-up(md) {
      bottom: 3.6rem;
      top: unset;
      align-items: flex-start;
      width: 60%;
      text-align: left;
      transform: none;
    }

    & h3, .h3 {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-transform: uppercase;
    }

    & p {
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  & .picsum-img-wrapper {
    position: relative;
    width: 100%;
    padding-top: 75%; // to keep 4:3 ratio
    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }
}

// dialog
.dark-backdrop {
  background-color: var(--color-fadeout);
}

.dialog-panel {
  max-width: 99vw !important;
  min-width: 99vw !important;
  @include media-breakpoint-up(md) {
    max-width: var(--container-width-md) !important;
    min-width: var(--container-width-md) !important;
  }
  @include media-breakpoint-up(lg) {
    max-width: var(--container-width-lg) !important;
    min-width: var(--container-width-lg) !important;
  }

  .mat-dialog-container {
    padding: 0;
  }
}

//Datatables styling
.dataTables_wrapper {
  .dataTables {
    &_length {
      & label {
        margin-bottom: 1rem;

        & select {
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: var(--color-font-primary);
          background: #ffffff;
          border: 1px solid var(--color-border);
          border-radius: 4px;
        }
      }
    }

    &_filter {
      & label {
        margin-bottom: 1rem;

        & input {
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: var(--color-font-primary);
          background: #ffffff;
          border: 1px solid var(--color-border);
          border-radius: 4px;
        }
      }
    }

    &_info {
      padding-top: 1rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: var(--color-font-primary);
    }

    &_paginate {
      padding-top: 0;
      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: space-between;
      }

      & .paginate_button {
        padding: 1rem;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: var(--color-button) !important;
        background: none !important;
        @include media-breakpoint-down(sm) {
          padding: 0.75rem;
        }

        &.previous {
          padding-left: 0;
        }

        &.next {
          padding-right: 0;
        }

        &:hover,
        &.current,
        &.current:hover {
          color: var(--color-button-hover) !important;
          border-color: transparent;
        }

        &.disabled,
        &.disabled:hover {
          color: var(--color-button) !important;
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }

  //  table styling
  .dataTable {
    margin: 1rem 0;
    border-bottom: none !important;
    width: 100% !important;

    & thead {
      & th {
        padding: 1.6rem 1.4rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;
        border: 1px solid var(--color-border);
        background-color: #ffffff;
        //background-image:
        color: #000000;

        &:not(:last-child) {
          border-right: none;
        }
      }

      // & .sorting_asc {
      //   background-image: url("/assets/icons/datatable_sort_asc.svg") !important;
      // }
      // & .sorting_asc {
      //   //background-image: url("/assets/icons/datatable_sort_asc.svg") !important;
      // }
    }

    & tbody {
      & tr {
        &.odd {
          background-color: #f5f5f5;

          & + .child {
            background-color: #f5f5f5;
          }
        }

        &.even {
          background-color: #ffffff;

          & + .child {
            background-color: #ffffff;
          }
        }

        & td {
          padding: 1.6rem 1.4rem;
          font-size: 1.4rem;
          line-height: 1.6rem;
          border-left: 1px solid var(--color-border);
          color: #000000;
          border-bottom: 1px solid var(--color-border);

          &:last-child {
            border-right: 1px solid var(--color-border);
          }
        }

        &.parent {
          & td {
            border-bottom: none;
          }
        }

        &.child {
          & ul {
            &.dtr-details {
              & > li {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

//missing responsive datatable styles

table.dataTable img {
  max-width: 150px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  top: 50%;
  left: 5px;
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #337ab7;
}

table.dataTable.dtr-inline.collapsed
> tbody
> tr.parent
> td.dtr-control:before,
table.dataTable.dtr-inline.collapsed
> tbody
> tr.parent
> th.dtr-control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th.dtr-control {
  padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact
> tbody
> tr
> td.dtr-control:before,
table.dataTable.dtr-inline.collapsed.compact
> tbody
> tr
> th.dtr-control:before {
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control,
table.dataTable.dtr-column > tbody > tr > th.dtr-control,
table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 0.8em;
  width: 0.8em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #337ab7;
}

table.dataTable.dtr-column > tbody > tr.parent td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}

table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}

div.dtr-bs-modal table.table tr:first-child td {
  border-top: none;
}

// search highlight
.preview .preview__lead b {
  color: var(--color-font-secondary);
  font-weight: bold;
}

.mat-snack-bar-container {
  display: flex !important;
  align-items: center;
  min-height: 70px !important;

  > div {
    width: 100%;

    .mat-simple-snackbar {
      font-size: 24px;
      line-height: 26px;
      width: 100%;
    }

    .mat-button {
      font-size: 16px !important;
    }
  }
}
